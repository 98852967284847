export default {
  headers: [
    {
      id: 'main-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/sipan/logo.png',
        url: 'https://www.providentrealestate.com/',
      },
      breakpoint: 1637,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-sipan-header-logo-container',
          imageClassName: '',
        },
        headerClassName: 'on-sipan-header',
        headerSectionsClassName: 'on-sipan-header-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-sipan-mheader-logo-container',
          imageClassName: 'on-sipan-mheader-logo',
        },
        type: 'mobile-center', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'sipan-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-sipan-mheader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-sipan-mheader-menu',
        itemClassName: 'on-sipan-mheader-item',
        menuClassName: 'on-sipan-mheader-folder',
        menuLabelActiveClassName: 'on-sipan-mheader-active',
        menuItemClassName: 'on-sipan-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'sipan-header-buy-item',
            content: 'Buy',
            url: 'https://search.providentrealestate.com/',
          },
          {
            id: 'sipan-header-sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'sipan-header-sell-item-valuation',
                content: 'Home Valuation',
                url: 'https://www.providentrealestate.com/home-valuation',
              },
              {
                id: 'sipan-header-sell-item-why-us',
                content: 'Why List With Us',
                url: 'https://www.providentrealestate.com/why-list-with-us',
              },
            ],
          },
          {
            id: 'sipan-header-trade-in-item',
            content: 'Exclusive Trade-In Program',
            url: 'https://trade-in.providentrealestate.com',
          },
          {
            id: 'sipan-header-communities-item',
            content: 'Communities',
            menuItems: [
              {
                id: 'sipan-header-communities-item-riverside',
                content: 'Riverside County',
                url: 'https://www.providentrealestate.com/communities/riverside-county-real-estate',
              },
              {
                id: 'sipan-header-communities-item-beaumont',
                content: 'Beaumont',
                url: 'https://www.providentrealestate.com/communities/beaumont-real-estate',
              },
              {
                id: 'sipan-header-communities-item-canyon',
                content: 'Canyon Lake',
                url: 'https://www.providentrealestate.com/communities/canyon-lake-real-estate',
              },
              {
                id: 'sipan-header-communities-item-corona',
                content: 'Corona',
                url: 'https://www.providentrealestate.com/communities/corona-real-estate',
              },
              {
                id: 'sipan-header-communities-item-eastvale',
                content: 'Eastvale',
                url: 'https://www.providentrealestate.com/communities/eastvale-real-estate',
              },
              {
                id: 'sipan-header-communities-item-jurupa',
                content: 'Jurupa Valley',
                url: 'https://www.providentrealestate.com/communities/jurupa-valley-real-estate',
              },
              {
                id: 'sipan-header-communities-item-elsinore',
                content: 'Lake Elsinore',
                url: 'https://www.providentrealestate.com/communities/lake-elsinore-real-estate',
              },
              {
                id: 'sipan-header-communities-item-matthews',
                content: 'Lake Matthews',
                url: 'https://www.providentrealestate.com/communities/lake-mathews-real-estate',
              },
              {
                id: 'sipan-header-communities-item-menifee',
                content: 'Menifee',
                url: 'https://www.providentrealestate.com/communities/menifee-real-estate',
              },
              {
                id: 'sipan-header-communities-item-moreno',
                content: 'Moreno',
                url: 'https://www.providentrealestate.com/communities/moreno-valley-real-estate',
              },
              {
                id: 'sipan-header-communities-item-more',
                content: 'More Communities',
                url: 'https://www.providentrealestate.com/communities',
              },
            ],
          },
          {
            id: 'sipan-header-testimonials-item',
            content: 'Testimonials',
            url: 'https://www.providentrealestate.com/testimonials',
          },
          {
            id: 'sipan-header-team-item',
            content: 'Team',
            url: 'https://www.providentrealestate.com/team-page',
          },
          {
            id: 'sipan-header-careers-item',
            content: 'Careers',
            url: 'https://www.providentrealestate.com/careers',
          },
          {
            id: 'sipan-header-contact-item',
            content: 'Contact Us',
            url: 'https://www.providentrealestate.com/new-contact-us',
          },
          {
            id: 'sipan-header-blog-item',
            content: 'Blog',
            url: 'https://www.providentrealestate.com/blog',
          },
          {
            id: 'sipan-header-home-item',
            content: 'Home',
            url: 'https://www.providentrealestate.com/',
          },
        ],
      },
    },
    {
      id: 'content-header',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/otok/logo.png',
        url: '',
      },
      breakpoint: 0,
      desktopMenu: {
        type: 'center', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-sipan-cheader',
        headerSectionsClassName: 'on-sipan-cheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-sipan-cheader-item',
        menuClassName: 'on-sipan-cheader-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-sipan-cheader-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'sipan-header-buy-item',
            content: 'Buy',
            url: 'https://search.providentrealestate.com/',
          },
          {
            id: 'sipan-header-sell-item',
            content: 'Sell',
            menuItems: [
              {
                id: 'sipan-header-sell-item-valuation',
                content: 'Home Valuation',
                url: 'https://www.providentrealestate.com/home-valuation',
              },
              {
                id: 'sipan-header-sell-item-why-us',
                content: 'Why List With Us',
                url: 'https://www.providentrealestate.com/why-list-with-us',
              },
            ],
          },
          {
            id: 'sipan-header-trade-in-item',
            content: 'Exclusive Trade-In Program',
            url: 'https://trade-in.providentrealestate.com',
          },
          {
            id: 'sipan-header-communities-item',
            content: 'Communities',
            menuItems: [
              {
                id: 'sipan-header-communities-item-riverside',
                content: 'Riverside County',
                url: 'https://www.providentrealestate.com/communities/riverside-county-real-estate',
              },
              {
                id: 'sipan-header-communities-item-beaumont',
                content: 'Beaumont',
                url: 'https://www.providentrealestate.com/communities/beaumont-real-estate',
              },
              {
                id: 'sipan-header-communities-item-canyon',
                content: 'Canyon Lake',
                url: 'https://www.providentrealestate.com/communities/canyon-lake-real-estate',
              },
              {
                id: 'sipan-header-communities-item-corona',
                content: 'Corona',
                url: 'https://www.providentrealestate.com/communities/corona-real-estate',
              },
              {
                id: 'sipan-header-communities-item-eastvale',
                content: 'Eastvale',
                url: 'https://www.providentrealestate.com/communities/eastvale-real-estate',
              },
              {
                id: 'sipan-header-communities-item-jurupa',
                content: 'Jurupa Valley',
                url: 'https://www.providentrealestate.com/communities/jurupa-valley-real-estate',
              },
              {
                id: 'sipan-header-communities-item-elsinore',
                content: 'Lake Elsinore',
                url: 'https://www.providentrealestate.com/communities/lake-elsinore-real-estate',
              },
              {
                id: 'sipan-header-communities-item-matthews',
                content: 'Lake Matthews',
                url: 'https://www.providentrealestate.com/communities/lake-mathews-real-estate',
              },
              {
                id: 'sipan-header-communities-item-menifee',
                content: 'Menifee',
                url: 'https://www.providentrealestate.com/communities/menifee-real-estate',
              },
              {
                id: 'sipan-header-communities-item-moreno',
                content: 'Moreno',
                url: 'https://www.providentrealestate.com/communities/moreno-valley-real-estate',
              },
              {
                id: 'sipan-header-communities-item-more',
                content: 'More Communities',
                url: 'https://www.providentrealestate.com/communities',
              },
            ],
          },
          {
            id: 'sipan-header-testimonials-item',
            content: 'Testimonials',
            url: 'https://www.providentrealestate.com/testimonials',
          },
          {
            id: 'sipan-header-team-item',
            content: 'Team',
            url: 'https://www.providentrealestate.com/team-page',
          },
          {
            id: 'sipan-header-careers-item',
            content: 'Careers',
            url: 'https://www.providentrealestate.com/careers',
          },
          {
            id: 'sipan-header-contact-item',
            content: 'Contact Us',
            url: 'https://www.providentrealestate.com/new-contact-us',
          },
          {
            id: 'sipan-header-blog-item',
            content: 'Blog',
            url: 'https://www.providentrealestate.com/blog',
          },
          {
            id: 'sipan-header-home-item',
            content: 'Home',
            url: 'https://www.providentrealestate.com/',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: '',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [],
      },
    },
  ],
  footers: [
    {
      id: 'on-sipan-footer-first',
      display: true,
      className: 'on-sipan-footer-section on-sipan-footer-section-first',
      sectionClassName: 'on-sipan-footer-section-container',
      columns: [
        {
          id: 'col-one',
          className: 'on-sipan-footer-column on-sipan-footer-column-one ',
          style: {},
          items: [
            {
              id: 'col-one-img',
              type: 'image',
              className: 'on-sipan-footer-img on-sipan-col-one-item',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/sipan/logo-footer.png',
              style: { width: '100%' },
            },
            {
              id: 'col-one-heading-one',
              type: 'heading',
              className: 'on-sipan-footer-copyright-text on-sipan-col-one-item',
              style: { fontWeight: 'bold' },
              data: '©Provident Real Estate 2022',
            },
            {
              id: 'col-one-heading-three',
              type: 'heading',
              className: 'on-sipan-footer-copyright-text on-sipan-col-one-item',
              style: {},
              data: 'All Rights Reserved',
            },
          ],
        },
        {
          id: 'col-two',
          className: 'on-sipan-footer-column on-sipan-footer-column-two ',
          style: { textAlign: 'left' },
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: 'on-sipan-footer-heading',
              style: {},
              data: 'POPULAR PAGES',
            },
            {
              id: 'col-two-menu',
              type: 'menu',
              className: 'on-sipan-footer-menu',
              itemClassName: 'mb-0 on-sipan-footer-menu-popular',
              items: [
                {
                  id: 'col-two-item-one',
                  data: 'Buy',
                  url: 'https://search.providentrealestate.com/',
                },
                {
                  id: 'col-two-item-two',
                  data: 'Sell',
                  url: 'https://www.providentrealestate.com/home-valuation',
                },
                {
                  id: 'col-two-item-three',
                  data: 'EXCLUSIVE TRADE-IN PROGRAM',
                  url: 'https://trade-in.providentrealestate.com/',
                },
                {
                  id: 'col-two-item-four',
                  data: 'Communities',
                  url: 'https://www.providentrealestate.com/communities',
                },
                {
                  id: 'col-two-item-five',
                  data: 'Testimonials',
                  url: 'https://www.providentrealestate.com/testimonials',
                },
                {
                  id: 'col-two-item-six',
                  data: 'Team',
                  url: 'https://www.providentrealestate.com/team-page',
                },
                {
                  id: 'col-two-item-seven',
                  data: 'Careers',
                  url: 'https://www.providentrealestate.com/careers',
                },
                {
                  id: 'col-two-item-eight',
                  data: 'Contact Us',
                  url: 'https://www.providentrealestate.com/new-contact-us',
                },
                {
                  id: 'col-two-item-nine',
                  data: 'Blog',
                  url: 'https://www.providentrealestate.com/blog',
                },
                {
                  id: 'col-two-item-ten',
                  data: 'Home',
                  url: 'https://www.providentrealestate.com/',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-sipan-footer-column on-sipan-footer-column-three ',
          style: { textAlign: 'left' },
          items: [
            {
              id: 'col-three-heading-two',
              type: 'heading',
              elementType: 'h1',
              className: 'on-sipan-footer-heading',
              style: {},
              data: 'OFFICE HOURS',
            },
            {
              id: 'col-three-menu-one',
              type: 'menu',
              className: 'on-sipan-footer-menu',
              itemClassName: 'on-sipan-footer-menu-items mb-0',
              style: {},
              items: [
                {
                  id: 'col-three-item-one',
                  data: 'Monday - 9:00AM - 5:00PM',
                },
                {
                  id: 'col-three-item-two',
                  data: 'Tuesday - 9:00AM - 5:00PM',
                },
                {
                  id: 'col-three-item-three',
                  data: 'Wednesday - 9:00AM - 5:00PM',
                },
                {
                  id: 'col-three-item-four',
                  data: 'Thursday - 9:00AM - 5:00PM',
                },
                {
                  id: 'col-three-item-five',
                  data: 'Friday - 9:00AM - 5:00PM',
                },
                {
                  id: 'col-three-item-six',
                  data: 'Saturday - Closed',
                },
                {
                  id: 'col-three-item-seven',
                  data: 'Sunday - Closed',
                },
              ],
            },
          ],
        },
        {
          id: 'col-four',
          className: 'on-sipan-footer-info  ',
          style: {},
          items: [
            {
              id: 'col-four-menu',
              type: 'menu',
              className: '',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-four-item-one',
                  data: 'Provident Real Estate',
                  style: { lineHeight: '1.2' },
                  className: 'on-sipan-footer-info-item',
                },
                {
                  id: 'col-four-item-two',
                  data: '5329 Hamner Avenue Suite 601',
                  className: 'on-sipan-footer-info-item',
                },
                {
                  id: 'col-four-item-three',
                  data: 'Eastvale, CA, 91752',
                  className: 'on-sipan-footer-info-item',
                },
              ],
            },
            {
              id: 'col-four-menu-two',
              type: 'itemContent',
              className: 'mb-0 on-sipan-footer-contact',
              style: {},
              items: [
                {
                  id: 'col-four-item-four',
                  content: 'Phone: ',
                },
                {
                  id: 'col-four-item-five',
                  content: '(951) 394-0820',
                  url: 'tel:(951) 394-0820',
                },
              ],
            },
            {
              id: 'col-four-menu-three',
              type: 'itemContent',
              className: 'mb-0 on-sipan-footer-contact',
              style: {},
              items: [
                {
                  id: 'col-four-item-six',
                  content: 'Email: ',
                },
                {
                  id: 'col-four-item-seven',
                  content: 'Info@Providentrealestate.Com',
                  url: 'mailto:info@providentrealestate.Com',
                },
              ],
            },
          ],
        },
        {
          id: 'col-five',
          className: 'on-sipan-footer-column on-sipan-footer-column-five ',
          style: {},
          items: [
            {
              id: 'col-five-img',
              type: 'image',
              className: 'on-sipan-footer-img',
              alt: 'img',
              url: 'https://si-homelight.s3.amazonaws.com/sites/sipan/logo-footer.png',
              style: { width: '100%' },
            },
            {
              id: 'col-one-heading-one',
              type: 'heading',
              className: 'on-sipan-footer-copyright-text on-sipan-col-one-item',
              style: { fontWeight: 'bold' },
              data: '©Provident Real Estate 2022',
            },
            {
              id: 'col-one-heading-three',
              type: 'heading',
              className: 'on-sipan-footer-copyright-text on-sipan-col-one-item',
              style: {},
              data: 'All Rights Reserved',
            },
          ],
        },
      ],
    },
    {
      id: 'on-sipan-footer-second',
      display: true,
      className: 'on-sipan-footer-section on-sipan-footer-section-last',
      sectionClassName: 'on-sipan-footer-section-container',
      columns: [
        {
          id: 'footer-disclaimer',
          className: 'align-center on-sipan-footer-privacy-col',
          style: {},
          items: [
            {
              id: 'footer-privacy-policy',
              type: 'heading',
              elementType: 'a',
              url: 'https://www.providentrealestate.com/#',
              className: 'on-sipan-footer-privacy-heading pt-5 pb-4',
              style: { fontSize: '13px' },
              data: 'PRIVACY POLICY',
            },
            {
              id: 'footer-privacy-text',
              type: 'heading',
              className: 'on-sipan-footer-text pt-3',
              elementType: 'p',
              style: {},
              data: 'Copyright © 2022 California Regional MLS. All Rights Reserved. Information herein is deemed reliable but not guaranteed and is provided exclusively for consumers personal, non-commercial use, and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data last refreshed Tue May 24 2022 08:13:41 GMT+0200 (Central European Summer Time).',
            },
            {
              id: 'footer-dmca',
              type: 'heading',
              url: 'https://search.providentrealestate.com/idx-information',
              className: 'on-sipan-footer-dmca',
              style: { fontSize: '16px', marginBottom: '10px' },
              data: 'DMCA Notice',
            },
            {
              id: 'footer-privacy-policy-two',
              type: 'heading',
              url: 'https://search.providentrealestate.com/privacy-policy',
              className: 'on-sipan-footer-privacy-heading',
              style: {},
              data: 'Privacy Policy',
            },
          ],
        },
      ],
    },
  ],

  substitute: {},
  replace: {},
  siteData: {
    agency: 'Provident Real Estate',
    agentName: 'Nazar Kalayji',
    agentDescription: 'Top agents in Southern California',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/sipan/agent.jpeg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/sipan/hero-bg.jpg',
  },
};
